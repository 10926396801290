$ = jQuery;

function initSearch() {
    $('.menu-item-25 a').click(function (event) {
        event.preventDefault();
        if ($(".search-form").hasClass("opened")) {
            $('.search-form').removeClass('opened');
        } else {
            $(".search-form").addClass("opened");

        }
    });
}

function initMobileSub() {
    $("#mobile-sub").detach().appendTo('#navbarNavDropdown');
    // 1205
}

function resizeSubNav() {
    if ($(window).innerWidth() > 1205) {
        $("#mobile-sub").css('display', 'none');
    } else {
        $("#mobile-sub").css('display', 'block');
    }
}

function initExternalLinks() {
    var extLink = $('a').filter(function () {
        return this.hostname && this.hostname !== location.hostname
    });
    extLink.each(function () {
        $(this).attr('target', '_blank');
    });

    $(document).on('click', 'a[href$=".pdf"]', function () {
        $(this).attr('target', "_blank");
    });
    // // Open all urls that don't belong to our domain in a new window or tab
    // $(document).on('click', "a[href^='http:']:not([href*='" + window.location.host + "'])", function () {
    //     $(this).attr("target", "_blank");
    // });
}

function initCardHeight() {
    // Select and loop the container element of the elements you want to equalise
    $('.row').each(function () {

        // Cache the highest
        var highestBox = 0;

        // Select and loop the elements you want to equalise
        $('.support-info-wrapper, this').each(function () {

            // If this box is higher than the cached highest then store it
            if ($(this).height() > highestBox) {
                highestBox = $(this).height();
            }

        });

        // Set the height of all those children to whichever was highest 

        $('.support-info-wrapper, this').height(highestBox);
        //var highestBox = (highestBox - 36);
        //$('.support-info, this').css('min-height', highestBox);
        console.log(highestBox);

    });
}


$(document).ready(function () {
    initSearch();
    initMobileSub();
    resizeSubNav();
    initExternalLinks();
    setTimeout(function () {
        initCardHeight();
    }, 500);
});

$(window).resize(function () {
    resizeSubNav();
    setTimeout(function () {
        initCardHeight();
    }, 500);
});